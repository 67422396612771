import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import dayjs from 'dayjs';
import axios from 'axios';
import DriverOrderDetialModal from '../Modal/DetialModal/DriverOrderDetialModal';

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Жолооч нэр',
  },
  {
    id: 'calories',
    numeric: true,
    disablePadding: false,
    label: 'Утасны дугаар',
  },
  {
    id: 'fat',
    numeric: true,
    disablePadding: false,
    label: `Хүргэлт / ${dayjs(Date.now()).format('YYYY-MM-DD')} /`,
  },
  {
    id: 'fat',
    numeric: true,
    disablePadding: false,
    label: `Маргаашийн хүргэлт / ${dayjs().add(1, 'D').format('YYYY-MM-DD')} /`,
  },
  //   {
  //     id: 'carbs',
  //     numeric: true,
  //     disablePadding: false,
  //     label: 'Нийт дүн',
  //   },
  {
    id: 'protein',
    numeric: true,
    disablePadding: false,
    label: 'Огноо',
  },
  //   {
  //     id: 'protein',
  //     numeric: true,
  //     disablePadding: false,
  //     label: 'Жолооч',
  //   },
  {
    id: 'protein',
    numeric: true,
    disablePadding: false,
    label: 'Үйлдэл',
  },
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
    props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ background: '#e2e2e2' }}>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={'center'}
            // padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected, click } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} сонгогдсон
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Жолооч нарын жагсаалт
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton onClick={click}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function DriverListTable({ users, orders, refresh, turnof }) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [driver, setDriver] = React.useState('');
  const [selected, setSelected] = React.useState([{ orderId: '', orderItems: [] }]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [isModal, setIsModal] = React.useState(false);
  const [id, setId] = React.useState('');

  const updateDriverToOrder = () => {
    refresh();
    axios.put('https://dev.turbodelivery.mn/user/driver/' + driver, {
      driverOrder: selected.filter((e) => e.orderId !== ''),
    })
      .then(() => turnof())
      .catch((e) => alert(e))
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = users
        .filter((e) => e.role === 'driver')
        .filter((e) => e.visible === false)
        .map((order) => ({
          orderId: order._id,
          orderItems: order.order,
        }));

      setSelected(newSelected);
      // alert(JSON.stringify(newSelected));
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id, orderItems) => {
    // alert(id);
    setId(id);
    setIsModal(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (id) => selected.findIndex((e) => e.orderId === id) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users
      .filter((e) => e.visible === false)
      .filter((e) => e.role === 'driver').length) : 0;

  const closeModal = () =>
    setIsModal(false);


  return (
    <>
      {isModal && id !== '' ? <DriverOrderDetialModal closeModal={closeModal} id={id} users={users} /> : <></>}
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '88vw', mb: 2 }}>
          <EnhancedTableToolbar click={updateDriverToOrder} numSelected={selected.filter((e) => e.orderId !== '').length} />
          <TableContainer>
            <Table
              sx={{ minWidth: 700 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <EnhancedTableHead
                numSelected={selected.filter((e) => e.orderId !== '').length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={users
                  .filter((e) => e.visible === false)
                  .filter((e) => e.role === 'driver').length}
              />
              <TableBody>
                {users
                  .filter((e) => e.visible === false)
                  .filter((e) => e.role === 'driver').map((row, index) => {
                    const isItemSelected = isSelected(row._id);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        onClick={(event) => handleClick(event, row._id, row.order)}
                        role="checkbox"
                        // aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        // selected={isItemSelected}
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell padding="checkbox">
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          align='center'
                          padding="none"
                        >
                          {row.name}
                        </TableCell>
                        <TableCell align="center">{row.phone}</TableCell>
                        <TableCell align="center">{
                          orders
                            .filter((e) => e.driver === row._id &&
                              ((e.isTommorow === false &&
                                dayjs(e.orderDate).subtract(8, 'hour')
                                  .isSame(dayjs(), 'date')) ||
                                (e.isTommorow === true && dayjs(e.orderDate).subtract(8, 'hour')
                                  .isSame(
                                    dayjs(), 'dates'
                                  )) || (e.status === 'Маргааш авах' && e.driverEdit === true
                                    && dayjs(e.orderDate).subtract(8, 'hour')
                                      .isSame(dayjs(), 'date')
                                )
                              )
                            ).length
                        }</TableCell>
                        <TableCell align="center">
                          {orders.filter((e) =>
                            e.driver === row._id
                          )
                            .filter((e) => {
                              // const currentDate = dayjs();
                              return (dayjs(e.orderDate).subtract(8, 'hour')
                                .isAfter(dayjs(), 'date')
                                && e.isTommorow === true) ||
                                (e.status === 'Маргааш авах' && e.driverEdit === true
                                  && dayjs(e.orderDate).subtract(8, 'hour')
                                    .isAfter(dayjs(), 'date'))
                            }).length
                          } ш/
                        </TableCell>
                        <TableCell align="center">{dayjs(row.createdAt).format('YYYY-MM-DD')}</TableCell>
                        <TableCell align="center">{row.protein}</TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={users
              .filter((e) => e.visible === false)
              .filter((e) => e.role === 'driver').length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Зайг шахах"
        />
      </Box>
    </>
  );
}