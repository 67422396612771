// import { Tooltip } from '@mui/material';
// import * as FileSaver from 'file-saver';
// import XLSX from 'sheetjs-style';
// import { RiFileExcel2Line } from "react-icons/ri";
// import dayjs from 'dayjs';

// const ExportExcel = ({ excelData, fileName, haveOrders, driver, drivers, shops }) => {
//     const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
//     const fileExtension = '.xlsx';

//     const exportToExcel = async () => {
//         const ws = XLSX.utils.json_to_sheet(excelData);

//         ws['!cols'] = Array(10).fill({ wch: 20 });

//         const wb = {
//             Sheets: { 'data': ws },
//             SheetNames: ['data'],
//             Props: {
//                 pageSetup: {
//                     paperSize: 9,
//                     orientation: 'portrait',
//                     fitToWidth: 1,
//                     fitToHeight: 1,
//                     scale: 100, // Use a scale for A4 page fit
//                 }
//             }
//         };

//         const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
//         const data = new Blob([excelBuffer], { type: fileType });
//         FileSaver.saveAs(data, fileName + fileExtension);
//     }
//     const exportToOrderExcel = () => {
//         const flatData = excelData.flatMap(order => {
//             return order.order.map(item => ({
//                 утас: parseInt(order.phone),
//                 хаяг: order.address,
//                 дэлгүүр: shops.find((shop) => shop._id === order.user)?.shop,
//                 төлөв: order.status,
//                 'нийт дүн': order.price,
//                 'Үүсгэсэн огноо': dayjs(order.createdAt).format('YYYY-MM-DD'),
//                 'Төлөв өөрчилсөн огноо': dayjs(order.orderDate).subtract(8, 'hour').format('YYYY-MM-DD'),
//                 жолооч: drivers.find((catchdriver) => catchdriver._id === order.driver)?.name,
//                 'бараа нэр.': item.name,
//                 'бараа дүн': item.price,
//                 'бараа тоо': item.unit,
//                 'хөнгөлөлт': parseInt(order.discount)
//             }));
//         });

//         const columnLengths = flatData.reduce((acc, row) => {
//             Object.entries(row).forEach(([key, value]) => {
//                 acc[key] = Math.max(acc[key] || 0, String(value).length);
//             });
//             return acc;
//         }, {});

//         const ws = XLSX.utils.json_to_sheet(flatData);

//         // Adjust column widths based on A4 page size (8.27 inches wide, ~63 characters)
//         ws['!cols'] = Object.keys(columnLengths).map((key) => ({
//             wch: Math.min(columnLengths[key] + 2, 20) // Limit column width to avoid oversized columns
//         }));

//         // Enable text wrapping for cells, especially for long columns like 'хаяг'
//         Object.keys(ws).forEach(cell => {
//             if (cell[0] !== '!') {
//                 ws[cell].s = {
//                     alignment: {
//                         wrapText: true, // Text wrap to move long text to the next line
//                         vertical: "top", // Align text to top
//                         horizontal: "center"
//                     }
//                 };
//             }
//         });

//         // Merge cells for 'хаяг' to span 2 rows if necessary
//         const mergeAddress = (ws) => {
//             const range = XLSX.utils.decode_range(ws['!ref']);
//             for (let R = range.s.r + 1; R <= range.e.r; ++R) {
//                 const addressCell = `B${R}`;
//                 if (ws[addressCell] && ws[addressCell].v.length > 20) {
//                     ws['!merges'] = (ws['!merges'] || []);
//                     ws['!merges'].push({ s: { r: R, c: 1 }, e: { r: R + 1, c: 1 } });
//                 }
//             }
//         };
//         mergeAddress(ws);

//         // Set the worksheet to fit A4 paper in the print setup
//         const wb = {
//             Sheets: { 'data': ws },
//             SheetNames: ['data'],
//             Props: {
//                 pageSetup: {
//                     paperSize: 9, // A4 size
//                     orientation: 'portrait',
//                     fitToWidth: 1,
//                     fitToHeight: 1,
//                     margins: {
//                         left: 0.7,
//                         right: 0.7,
//                         top: 0.75,
//                         bottom: 0.75,
//                         header: 0.3,
//                         footer: 0.3
//                     }
//                 }
//             }
//         };

//         const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
//         const data = new Blob([excelBuffer], { type: fileType });
//         FileSaver.saveAs(data, fileName + fileExtension);
//     };

//     return (
//         <>
//             <Tooltip>
//                 {haveOrders ? <button style={{ color: 'green', display: 'flex', alignItems: 'center', gap: '10px' }}
//                     className='button-white' onClick={(e) => exportToOrderExcel(fileName)}>
//                     <RiFileExcel2Line color='green' />
//                     Excel File
//                 </button> :
//                     <button style={{ color: 'green', display: 'flex', alignItems: 'center', gap: '10px' }}
//                         className='button-white' onClick={(e) => exportToExcel(fileName)}>
//                         <RiFileExcel2Line color='green' />
//                         Excel File
//                     </button>}
//             </Tooltip>
//         </>
//     )
// }

// export default ExportExcel;


import { Tooltip } from '@mui/material';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { RiFileExcel2Line } from "react-icons/ri";
import dayjs from 'dayjs';

const ExportExcel = ({ excelData, fileName, haveOrders, driver, drivers, shops }) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToExcel = async () => {
        const ws = XLSX.utils.json_to_sheet(excelData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    const exportToOrderExcel = () => {
        const flatData = excelData.flatMap(order => {
            return order.order.map(item => ({
                утас: parseInt(order.phone),
                хаяг: order.address,
                дэлгүүр: shops.find((shop) => shop.user === order.user)?.name,
                төлөв: order.status,
                'нийт дүн': order.price,
                'Үүсгэсэн огноо': dayjs(order.createdAt).format('YYYY-MM-DD'),
                'Төлөв өөрчилсөн огноо': dayjs(order.orderDate).subtract(8, 'hour').format('YYYY-MM-DD'),
                жолооч: drivers.find((catchdriver) => catchdriver._id === order.driver)?.name,
                'бараа нэр': item.name,
                'бараа дүн': item.price,
                'бараа тоо': item.unit,
                'хөнгөлөлт': parseInt(order.discount)
            }));
        });

        const columnLengths = flatData.reduce((acc, row) => {
            Object.entries(row).forEach(([key, value]) => {
                acc[key] = Math.max(acc[key] || 0, String(value).length);
            });
            return acc;
        }, {});

        const ws = XLSX.utils.json_to_sheet(flatData);
        Object.keys(columnLengths).forEach((key) => {
            ws[`!cols`] = ws[`!cols`] || [];
            ws[`!cols`].push({ wch: columnLengths[key] + 5 });
        });

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <>
            <Tooltip>
                {haveOrders ? <button style={{ color: 'green', display: 'flex', alignItems: 'center', gap: '10px' }}
                    className='button-white' onClick={(e) => exportToOrderExcel(fileName)}>
                    <RiFileExcel2Line color='green' />
                    Excel File
                </button> :
                    <button style={{ color: 'green', display: 'flex', alignItems: 'center', gap: '10px' }}
                        className='button-white' onClick={(e) => exportToExcel(fileName)}>
                        <RiFileExcel2Line color='green' />
                        Excel File
                    </button>}
            </Tooltip>
        </>
    )
}

export default ExportExcel;