import React, { useEffect, useState } from 'react'
// import ContainerAddTable from '../Table/AddTable/ContainerAddTable'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IoTrashBin } from "react-icons/io5";
import axios from 'axios';

const MinusContainerModal = ({ closeModal }) => {
  const [items, setItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [container, setContainer] = useState('');
  const [location, setLocation] = useState('');
  const [shop, setShop] = useState('');
  const [shops, setShops] = useState([]);
  const [containers, setContainers] = useState([]);
  const [inputs, setInputs] = useState([{ name: "", comment: '', unit: "", reportId: '', staticUnit: '', status: 'Зарлага' }]);
  const [lastUnit, setLastUnit] = useState([]);
  const [user, setUser] = useState('');
  // const id = useId();

  useEffect(() => {
    if (!isLoading) {
      // console.log(inputs[inputs.length - 1].unit);
      const lastItemBalance = inputs.filter((e) => parseInt(e.unit) === 0 || e.unit === '');

      // console.log('last' + lastItemBalance);

      setLastUnit(lastItemBalance);
    }

    if (isLoading) {
      axios.get('https://dev.turbodelivery.mn/areacontainer')
        .then((e) => {
          setContainers(e.data.data);
        })
        .catch((e) => console.log(e));

      axios.get('https://dev.turbodelivery.mn/post')
        .then((e) => {
          // setIsLoading(false);
          setItems(e.data);
        })
        .catch((e) => console.log(e));

      axios.get('https://dev.turbodelivery.mn/user?role=user')
        .then((e) => {
          setIsLoading(false);
          setShops(e.data.data.filter((e) => e.visible !== 'true'));
        })
        .catch((e) => console.log(e));
    }
  }, [isLoading, inputs]);

  const createContainer = () => {
    axios.post('https://dev.turbodelivery.mn/container/minus', {
      item: inputs,
      canceled: true,
      container: container,
      location: location,
      // shop: shop,
      user: shop,
      // user: user._id,
    })
      .then(() => {
        window.location.reload(false);
        closeModal(false);
      })
      .catch((e) => console.log(e))
  }

  const handleChange = (event, index) => {
    let { name, value } = event.target;
    let onChangeValue = [...inputs];
    onChangeValue[index][name] = value;

    // Update staticUnit to the same value as unit
    onChangeValue[index]['staticUnit'] = onChangeValue[index]['unit'];

    setInputs(onChangeValue);
  };


  const handleAddInput = () => {
    setInputs([...inputs, { name: "", unit: "", reportId: "", staticUnit: '', comment: '', status: 'Зарлага' }]);
  }

  const handleDeleteInput = (index) => {
    const newArray = [...inputs];
    newArray.splice(index, 1);
    setInputs(newArray);
  };

  // const calculateTotalBalance = ({ name }) => {
  //   return items
  //     .filter((e) => e.title.includes(name))
  //     .reduce((total, items) => {
  //       return (
  //         total +
  //         items.balance
  //           .filter((el) => el.status === true)
  //           .reduce((itemTotal, item) => itemTotal + item.unit, 0)
  //       );
  //     }, 0);
  // };

  // const calculateTotalCanceled = ({ name }) => {
  //   return items.filter((e) => e.title.includes(name)).reduce((total, items) => {
  //     return total + items.canceled.reduce((itemTotal, item) => itemTotal + item.unit, 0);
  //   }, 0);
  // };

  // const calculateTotalDelivered = ({ name }) => {
  //   return items.filter((e) => e.title.includes(name)).reduce((total, items) => {
  //     return total + items.delivered.reduce((itemTotal, item) => itemTotal + item.unit, 0);
  //   }, 0);
  // };

  // const calculateTotalRemoved = ({ name }) => {
  //   return items.filter((e) => e.title.includes(name)).reduce((total, items) => {
  //     return total + items.removed.reduce((itemTotal, item) => itemTotal + item.unit, 0);
  //   }, 0);
  // };

  return (
    <div className='blur_container'>
      <div className='modal' style={{ width: '60vw' }}>
        <div className='two-line' style={{ width: '60vw' }}>
          <h2>Агуулахаас бараа хасах</h2>
          <h2 style={{ cursor: 'pointer' }} onClick={closeModal}>X</h2>
        </div>
        <br />
        <label>
          Агуулах сонгох:
          <select onChange={(e) => setContainer(e.target.value)} name="" id="">
            <option value="">Сонгох</option>
            {
              containers.map((e) => (
                <option value={e.name}>{e.name}</option>
              ))
            }
          </select>
        </label>
        <label>
          {/* {shop} */}
          Дэлгүүр сонгох:
          <select onChange={(e) => setShop(e.target.value)} name="" id="">
            <option value="">Сонгох</option>
            {/* <option value="2">2</option> */}
            {isLoading == false ? shops.map((e) => (
              <option value={e._id}>{e.shop}</option>
            )) : <></>}
          </select>
        </label>
        <button onClick={() => handleAddInput()} className='button-white'>Мөр Нэмэх</button>
        {/* <ContainerAddTable unit_arrey={inputs}/> */}
        <TableContainer style={{ width: '60vw' }} component={Paper}>
          <Table sx={{ minWidth: '40vw', maxWidth: '60vw' }} style={{ width: '60vw' }} aria-label="simple table">
            <TableHead>
              <TableRow style={{ background: '#2c9961', color: '#fff' }}>
                <TableCell style={{ color: '#fff', fontSize: 16 }}>Бараа</TableCell>
                {/* <TableCell style={{ color: '#fff', fontSize: 16 }}>Үлдэгдэл</TableCell> */}
                <TableCell style={{ color: '#fff', fontSize: 16 }}>Тоо хэмжээ</TableCell>
                <TableCell style={{ color: '#fff', fontSize: 16 }}>Тайлбар</TableCell>
                <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Үйлдэл</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {inputs.map((row, index) => (
                <TableRow
                  className='hover'
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  key={index}
                >
                  <TableCell component="th" scope="row">
                    <select name="name" id="" onChange={(e) => handleChange(e, index)}>
                      <option value="">Сонгох</option>
                      {items.filter((e) => shop ? e.user === shop : e).map((e) => (
                        <option key={e.id} value={e.title}>{e.title}</option>
                      ))}
                    </select>
                  </TableCell>
                  {/* <TableCell>
                    <p>{
                      items.filter((e) => e.title === row.name ? e.title === row.name : '').map((e) => (
                        <p>{
                          calculateTotalBalance({ name: e.title }) -
                          calculateTotalDelivered({ name: e.title }) +
                          calculateTotalCanceled({ name: e.title }) -
                          calculateTotalRemoved({ name: e.title })
                        }</p>
                      ))
                    }</p>
                  </TableCell> */}
                  <TableCell align="center">
                    <input type="number" name='unit' onChange={(e) => handleChange(e, index)} id="" />
                  </TableCell>
                  <TableCell align="center">
                    <input type="text" name='comment' onChange={(e) => handleChange(e, index)} id="" />
                  </TableCell>
                  <TableCell align="center">
                    {/* uildel */}
                    {/* <input style={{visibility: 'hidden'}} type="number" name='staticUnit' onChange={(e) => handleChange(e, index)} id="" /> */}
                    <IoTrashBin size={30} color='red' onClick={handleDeleteInput} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <div className="body"> {JSON.stringify(inputs)} </div> */}
        <div className='button_container'>
          {lastUnit.length === 0 ? <button onClick={createContainer} className='button-white' style={{ color: '#2c9961' }}>Хадгалах</button> :
            <button className='button-white' style={{ color: '#fff', background: '#d3d3d3' }}>Хадгалах</button>}
          <button onClick={closeModal} className='button-white' style={{ color: 'red' }}>Хаах</button>
        </div>
      </div>
    </div>
  )
}

export default MinusContainerModal;
