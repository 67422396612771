import axios from 'axios';
import React, { useEffect, useState } from 'react'

const AddContainerModal = ({ closeModal }) => {
    const [name, setName] = useState('');
    const [detial, setDetial] = useState('');
    const [owner, setOwner] = useState('');
    const [ownerphone, setOwnerphone] = useState('');
    const [user, setUser] = useState('');
    const [users, setUsers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (isLoading) {
            axios.get('https://dev.turbodelivery.mn/user?role=operator')
                .then((e) => {
                    setUsers(e.data.data);
                    setIsLoading(false);
                })
                .catch((e) => console.log(e))
        }
    }, [isLoading])

    const addCategory = () => {
        axios.post('https://dev.turbodelivery.mn/areacontainer', {
            name: name,
            user: user,
            owner: owner,
            address: detial,
            ownerPhone: ownerphone
        })
            .then(() => window.location.reload(false))
            .catch((e) => e)
    }
    return (
        <div className='blur_container'>
            <div className='modal'>
                <div className='two-line'>
                    <h2>Агуулах нэмэх</h2>
                    <h2 style={{ cursor: 'pointer' }} onClick={closeModal}>X</h2>
                </div>
                <label>
                    Агуулах нэр:
                    <input type="text" onChange={(e) => setName(e.target.value)} value={name} />
                </label>
                <label>
                    Дэлгэрэнгүй тайлбар:
                    <input type="text" onChange={(e) => setDetial(e.target.value)} value={detial} />
                </label>
                <label>
                    Нярав сонгох:
                    <select name="" id="" onChange={(e) => setUser(e.target.value)}>
                        <option value="">Сонгох</option>
                        {users.map((e) => (
                            <option value={e._id}>{e.name}</option>
                        ))}
                    </select>
                </label>
                <label>
                    Агуулах эзэмшигчийн нэр:
                    <input type="text" onChange={(e) => setOwner(e.target.value)} value={owner} />
                </label>
                <label>
                    Агуулах эзэмшигчийн дугаар:
                    <input type="text" onChange={(e) => setOwnerphone(e.target.value)} value={ownerphone} />
                </label>
                <div className='button_container'>
                    <button onClick={addCategory} className='button-white' style={{ color: '#2c9961' }}>Хадгалах</button>
                    <button onClick={closeModal} className='button-white' style={{ color: 'red' }}>Хаах</button>
                </div>
            </div>
        </div>
    )
}

export default AddContainerModal
