import React, { useEffect, useState } from 'react'
import styles from './Order.module.css'
// import OrderTable from '../../Utills/Table/OrderTable';
import Navbar from '../../Components/Navbar/Navbar';
import OrderAddModal from '../../Utills/Modal/OrderAddModal';
import axios from 'axios';
import OrderTable from '../../Utills/Table/OrderTable';
import EditOrderModal from '../../Utills/Modal/EditModal/EditOrderModal';
import add_icon from '../../Assets/uttils/add_button.svg'
import ExportExcel from '../../excelexport';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { useParams } from 'react-router-dom';

const OrderFiltered = () => {
    const { id, date } = useParams();
    const [isModal, setIsModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [editId, setEditId] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [orders, setOrders] = useState([]);
    const [drivers, setDriver] = useState([]);
    const [users, setUsers] = useState([]);
    const [shops, setShops] = useState([]);
    const [user, setUser] = useState('');
    const [status, setStatus] = useState('');
    const [excelData, setExcelData] = useState([{ утас: '', хаяг: '', төлөв: '', 'нийт дүн': '', огноо: '', жолооч: '' }])
    const [isEndCalendar, setIsEndCalendar] = useState(false);
    const [endValue, setEndValue] = useState(dayjs(date));
    const [phone, setPhone] = useState('');
    const [isSearch, setSearch] = useState(false);

    const closeModal = () => {
        setIsModal(false);
        setEditModal(false);
    }

    useEffect(() => {
        // alert(date);
        window.localStorage.setItem('side-id', 1);
        if (isLoading) {
            axios.get('https://dev.turbodelivery.mn/user?sort=-createdAt&role=driver')
                .then((e) => {
                    setDriver(e.data.data);
                })
                .catch((e) => console.log(e));

            axios.get('https://dev.turbodelivery.mn/order?sort=-createdAt')
                .then((order) => {
                    setOrders(order.data.data);
                    setIsLoading(false);
                })
                .catch((e) => console.log(e));

            axios.get('https://dev.turbodelivery.mn/user?role=user')
                .then((e) => {
                    setUsers(e.data.data);
                    setIsLoading(false);
                });

            // axios.get('https://dev.turbodelivery.mn/shop')
            //     .then((e) => {
            //         setShops(e.data.data.filter((e) => e.visible !== 'true'));
            //         setIsLoading(false);
            //     });
        }

        // if (!isLoading) {
        const exceldata =
            orders.map((el) => ({
                утас: el.phone,
                хаяг: el.address,
                төлөв: el.status,
                'нийт дүн': new Intl.NumberFormat('en-Us').format(el.price),
                огноо: dayjs(el.createdAt).format('YYYY-MM-DD'),
                жолооч: drivers.find((catchdriver) => catchdriver._id === el.driver)?.name,
            }));
        setExcelData(exceldata);
        // }


    }, [isLoading, drivers, orders])

    const edit = ({ id }) => {
        setEditId(id);
        setEditModal(true);
    }

    return (
        <>
            {!isModal ? <Navbar title={'Захиалга'} /> : <></>}
            {isModal ? <div className='abs'><OrderAddModal closeModal={closeModal} /></div> : <></>}
            {editModal ? <div className='abs'><EditOrderModal id={editId} closeModal={closeModal} /></div> : <></>}
            {isLoading ? <div className="loader-container"><div className='loader'></div></div> : <></>}
            <div className={styles.container}>
                <div className='line-start'>
                    <button onClick={() => setIsModal(true)} className='button'><img src={add_icon} className='icon' alt="" /> Нэмэх</button>
                    {/* <button className='button-white'>See Item</button> */}
                    {/* <button className='button-white'>Print</button> */}
                    <ExportExcel fileName={'name'} excelData={excelData} />
                    <label>
                        Дэлгүүрээр шүүх:
                        <select name="" id="" onChange={(e) => setUser(e.target.value)}>
                            <option value=''>Бүгд</option>
                            {users.map((e) => (
                                <option value={e._id}>{e.shop}</option>
                            ))}
                        </select>
                    </label>
                    {/* <label>
                        Төлвөөр шүүх:
                        <select name="" id="" onChange={(e) => setStatus(e.target.value)}>
                            <option value=''>Бүгд</option>
                            <option value='Хүлээгдэж байна'>Хүлээгдэж байна</option>
                            <option value='Буцаагдсан'>Буцаагдсан</option>
                            <option value='Жолооч авсан'>Жолооч авсан</option>
                            <option value='Маргааш авах'>Маргааш авах</option>
                            <option value='Хойшлуулсан'>Хойшлуулсан</option>
                            <option value='Хүргэсэн'>Хүргэсэн</option>
                            <option value='Хаягаар очсон'>Хаягаар очсон</option>
                            <option value='Хэсэгчлэн хүргэсэн'>Хэсэгчлэн хүргэсэн</option>
                        </select>
                    </label> */}

                    {/* <div className="line-start"> */}
                    <label style={{ width: '18vw' }}>
                        Дугаараар хайх:
                        <input type="text" value={phone} onChange={(e) => setPhone(e.target.value)} name="" id="" />
                    </label>
                    <div style={{ width: '12vw', zIndex: 1 }}>
                        {isEndCalendar ? (
                            <div className="calendar-div">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                                        <DemoItem>
                                            <p onClick={() => setIsEndCalendar(false)}>Hide</p>
                                            <DateCalendar value={endValue}
                                                onChange={(newValue) => { setEndValue(newValue); setIsEndCalendar(false) }} />
                                        </DemoItem>
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>
                        ) : (
                            <label className='calendar-label' onClick={() => setIsEndCalendar(true)}>
                                {/* End date: */}
                                Он сар:
                                <p className='calendar'>{endValue.format('YYYY-MM-DD')}</p>
                            </label>
                        )}
                    </div>
                    {isSearch ? <button style={{ fontSize: '14px', padding: '5px 7px' }} className='button-white'
                        onClick={() => setSearch(false)}
                    >
                        Бүгдийг харах
                    </button> :
                        <button style={{ fontSize: '14px', padding: '5px 25px' }} className='button'
                            onClick={() => setSearch(true)}
                        >
                            Хайх
                        </button>}
                    {/* </div> */}
                </div>
                <br />
                <br />
                <div>
                    {/* <OrderItemTable orders={orders}/> */}
                    <OrderTable orders={orders
                        .filter((e) => phone ? e.phone.includes(phone) : e)
                        .filter((e) => e.status === 'Хүлээгдэж байна' ? e :
                            dayjs(e.orderDate).subtract(8, 'hour').format('YYYY-MM-DD') === endValue.format('YYYY-MM-DD')
                        )
                        // .filter((e) => status ? e.status === status : e)
                        .filter((e) => id === 'Хүргэсэн' ?
                            e.status === 'Хэсэгчлэн хүргэсэн' || e.status === 'Хүргэсэн'
                            : id === 'Хойшлуулсан' ?
                                e.status === 'Маргааш авах' || e.status === 'Хойшлуулсан'
                                : e.status === id)
                    }
                        drivers={drivers} edit={edit} user={users} filteruser={user} />
                </div>
            </div>
        </>
    )
}

export default OrderFiltered
