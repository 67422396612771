import React, { useEffect, useState } from 'react'
import styles from './Message.module.css'
import Navbar from '../../Components/Navbar/Navbar'
import axios from 'axios'
import MessageAddModal from '../../Utills/Modal/AddMessageModal'
import MessageTable from '../../Utills/Table/MessageTable'
import add_icon from '../../Assets/uttils/add_button.svg'

const Message = () => {
    const [isModal, setIsModal] = useState(false);
    const [messages, setMessages] = useState([]);
    const [isLoad, setIsLoad] = useState(true);

    useEffect(() => {
        if (isLoad) {
            window.localStorage.setItem('side-id', 12);
            axios.get('https://dev.turbodelivery.mn/msg')
                .then((e) => {
                    setMessages(e.data.data);
                    setIsLoad(false);
                    window.localStorage.setItem('categories', JSON.stringify(e.data.data));
                })
                .catch((e) => console.log(e));
        }
    }, [isLoad])

    const closeModal = () => {
        setIsModal(false);
    }
    return (
        <>
            {!isModal ? <Navbar title={'Зарын самбар'} /> : <></>}
            {isModal ? <MessageAddModal closeModal={closeModal} /> : <></>}
            {isLoad ? <div className='loader-container'><div className="loader"></div></div> : <></>}
            <div className="space-between" style={{ justifyContent: 'space-between', paddingRight: '2vw' }}>
                <button style={{ marginLeft: '11vw', marginTop: '80px' }} onClick={() => setIsModal(true)} className='button'>
                    <img src={add_icon} className='icon' alt="" /> Нэмэх
                </button>
            </div>
            <div className={styles.container}>
                <MessageTable messages={messages} />
            </div>
        </>
    )
}

export default Message
