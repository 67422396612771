import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination'; // Энэ мөрөнд TablePagination компонентийг нэмнэ
import { CiEdit, CiTrash } from "react-icons/ci";
import CategoryEditModal from '../Modal/EditModal/CategoryEditModal';
import EditStateModal from '../Modal/EditModal/StateEditModal';
import axios from 'axios';

export default function StateTable({ states }) {
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [page, setPage] = React.useState(0); // Энэ мөрөнд page-г нэмнэ
    const [id, setId] = React.useState('');
    const [isModal, setModal] = React.useState(false);
    const [data, setData] = React.useState({});

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const closeModal = () => {
        setModal(false);
    }

    const deleteState = () => {
        if (window.confirm('Та устгах үйлдэл хийх үү?') && id) {
            axios.delete('https://dev.turbodelivery.mn/state/id/' + id)
                .then(() => window.location.reload(false));
        }
    }
    return (
        <>
            {isModal && id ? <EditStateModal id={id} data={data} closeModal={closeModal} /> : null}
            <TableContainer style={{ maxHeight: '75vh', overflowY: 'auto' }} component={Paper}>
                <Table sx={{ minWidth: 650, maxWidth: '88vw' }} aria-label="simple table">
                    <TableHead>
                        <TableRow style={{ background: '#2c9961', color: '#fff' }}>
                            <TableCell style={{ color: '#fff', fontSize: 16 }}>Id</TableCell>
                            <TableCell style={{ color: '#fff', fontSize: 16 }}>Бүс нэр</TableCell>
                            <TableCell style={{ color: '#fff', fontSize: 16 }}>Бүсийн хороо дүүрэг</TableCell>
                            <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Үйлдэл</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {states
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => (
                                <TableRow className='hover'
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left">{index + 1}</TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.name}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                    </TableCell>
                                    <TableCell align="center">
                                        <CiEdit
                                            onClick={() => {
                                                setModal(true);
                                                setId(row._id);
                                                setData(row);
                                            }}
                                            size={30} style={{ cursor: 'pointer' }} color='blue'
                                        />
                                        <CiTrash
                                            onClick={() => {
                                                deleteState();
                                                setId(row._id);
                                            }}
                                            size={30} style={{ cursor: 'pointer' }} color='red'
                                        />
                                    </TableCell>

                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    count={states.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </>
    );
}