import axios from 'axios';
import React, { useState } from 'react'

const ShopsAddModal = ({ closeModal }) => {
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [city, setCity] = useState('');
    const [street, setStreet] = useState('');
    const [desc, setDesc] = useState('');

    // const user = 


    const addShop = () => {
        axios.post('https://dev.turbodelivery.mn/shop', {
            name: name,
            phone: phone,
            city: city,
            street: street,
            description: desc,
            // user: user._id, 
        })
            .then(() => window.location.reload(false))
            .catch(() => console.log('object'));
    }
    return (
        <div className='blur_container'>
            <div className='modal'>
                <div className='two-line'>
                    <h2>Дэлгүүр нэмэх</h2>
                    <h2 style={{ cursor: 'pointer' }} onClick={closeModal}>X</h2>
                </div>
                <label>
                    Дэлгүүрийн нэр:
                    <input
                        type="text"
                        value={name}
                        placeholder='Дэлгүүрийн нэр'
                        onChange={(e) => setName(e.target.value)}
                    />
                </label>
                <label>
                    Утас:
                    <input
                        type="text"
                        value={phone}
                        placeholder='Утас'
                        onChange={(e) => setPhone(e.target.value)}
                    />
                </label>
                <label>
                    Дүүрэг сонгох:
                    <select onChange={(e) => setCity(e.target.value)}>
                        <option value="">Сонгох</option>
                        <option value="Багануур">Багануур</option>
                        <option value="Багахангай">Багахангай</option>
                        <option value="Баянгол">Баянгол</option>
                        <option value="Баянзүрх">Баянзүрх</option>
                        <option value="Налайх">Налайх</option>
                        <option value="Сонгинохайрхан">Сонгинохайрхан</option>
                        <option value="Сүхбаатар">Сүхбаатар</option>
                        <option value="Хан-Уул">Хан-Уул</option>
                        <option value="Чингэлтэй">Чингэлтэй</option>
                    </select>
                </label>
                {/* <label>
                    Хороо сонгох:
                    <select onChange={(e) => setStreet(e.target.value)}>
                        <option value="1w">1</option>
                        <option value="2w">2</option>
                    </select>
                </label> */}
                <label>
                    Дэлгэрэнгүй хаяг:
                    <textarea
                        value={desc}
                        onChange={(e) => setDesc(e.target.value)}
                        cols="40"
                        rows="5"
                    ></textarea>
                </label>
                <div className='button_container'>
                    <button onClick={addShop} className='button-white' style={{ color: '#2c9961' }}>Хадгалах</button>
                    <button onClick={closeModal} className='button-white' style={{ color: 'red' }}>Хаах</button>
                </div>
            </div>
        </div>
    )
}

export default ShopsAddModal
