import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import Navbar from '../../../Components/Navbar/Navbar'
import ReportContainerTable from '../../../Utills/Table/ReportContainerTable';
import axios from 'axios';
import styles from './ContainerReport.module.css'

const ContainerReport = ({ ordersCatch }) => {
  const [startValue, setStartValue] = useState(dayjs(Date.now()));
  const [endValue, setEndValue] = useState(dayjs(Date.now()));
  const [isStartCalendar, setIsStartCalendar] = useState(false);
  const [isEndCalendar, setIsEndCalendar] = useState(false);
  const [reports, setReports] = useState([]);
  const [users, setUsers] = useState([]);
  const [shops, setShops] = useState([]);
  const [user, setUser] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState(ordersCatch);
  const [container, setContainer] = useState([]);
  const [areacontainers, setAreaContainers] = useState([]);
  const [areacontainer, setAreaContainer] = useState('');
  const [searchName, setSearchName] = useState('');

  useEffect(() => {
    window.localStorage.setItem('side-id', 4);
    if (isLoading) {

      axios.get('https://dev.turbodelivery.mn/post')
        .then((e) => {
          setReports(e.data);
          setIsLoading(false);
        })
        .catch((e) => alert(e));

      // axios.get('https://dev.turbodelivery.mn/back')
      //   .then((e) => {
      //     setIsLoading(false);
      //   })
      //   .catch((e) => alert(e));

      axios.get('https://dev.turbodelivery.mn/user?role=user')
        .then((e) => {
          setUsers(e.data.data);
        })
        .catch((e) => alert(e));

      axios.get('https://dev.turbodelivery.mn/areacontainer')
        .then((e) => {
          setAreaContainers(e.data.data);
        })
        .catch((e) => alert(e));

      // axios.get('https://dev.turbodelivery.mn/shop')
      //   .then((e) => {
      //     setShops(e.data.data.filter((e) => e.visible !== 'true'));
      //   })
      //   .catch((e) => alert(e));

      axios.get('https://dev.turbodelivery.mn/container')
        .then((e) => {
          setContainer(e.data.data);
          setIsLoading(false);
        })
        .catch((e) => alert(e));
    }
  }, [isLoading])

  // new dayjs(Date.now()) > startValue ? alert('date') : alert('false');
  return (
    <>
      {isLoading ? <div className="loader-container"><div className='loader'></div></div> : <></>}
      <Navbar title={'Агуулахын тайлан'} />
      <div className={styles.container}>
        <div className={styles.column}>
          <div className='flex-space-between'>
            <div className='space-between'>
              <div style={{ width: '20vw' }}>
                {isEndCalendar ? (
                  <div className='calendar-div' style={{ zIndex: 1 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                        <DemoItem>
                          <p onClick={() => setIsEndCalendar(false)}>Hide</p>
                          <DateCalendar value={endValue} onChange={(newValue) => { setEndValue(newValue); setIsEndCalendar(false) }} />
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                ) : (
                  <label className='calendar-label' onClick={() => setIsEndCalendar(true)}>
                    {/* End date: */}
                    Эхлэх хугацаа:
                    <p className='calendar'>{endValue.format('YYYY-MM-DD')}</p>
                  </label>
                )}
              </div>
              <div style={{ width: '20vw', zIndex: 1 }}>
                {isStartCalendar ? (
                  <div className='calendar-div'>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                        <DemoItem>
                          <p onClick={() => setIsStartCalendar(false)}>Hide</p>
                          <DateCalendar value={startValue} onChange={(newValue) => { setStartValue(newValue); setIsStartCalendar(false) }} />
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                ) : (
                  <label className='calendar-label' onClick={() => setIsStartCalendar(true)}>
                    {/* Start date: */}
                    Дуусах хугацаа:
                    <p className='calendar'>{startValue.format('YYYY-MM-DD')}</p>
                  </label>
                )}
              </div>
            </div>
            <label>
              Барааны үлдэгдлээр:
              <select name="" id="">
                <option value="">Сонгох</option>
              </select>
            </label>
            <br />
            {/* <button className='button'>Search</button> */}
          </div>
          <br />
          <div className="flex-space-between">
            <label>
              Дэлгүүрээр шүүх:
              <select onChange={(e) => setUser(e.target.value)} name="" id="">
                <option value="">Сонгох</option>
                {users.map((e) => (
                  <option value={e._id}>{e.shop}</option>
                ))}
              </select>
            </label>
            <label>
              Агуулахаар шүүх:
              <select onChange={(e) => setAreaContainer(e.target.value)} name="" id="">
                <option value="">Сонгох</option>
                {areacontainers.map((e) => (
                  <option value={e._id}>{e.name}</option>
                ))}
              </select>
            </label>
            <label htmlFor="">
              Бараа хайх:
              <input type="text" value={searchName} onChange={(e) => setSearchName(e.target.value)}
                placeholder='Барааны нэрээр хайх' name="" id="" />
            </label>
          </div>
        </div>
        <br />
        {isLoading ? <div className="loader"></div> : <ReportContainerTable
          container={container
            .filter((e) => user ? e.user === user : e)
            // .filter((e) => areacontainer ? e.areacontainer === areacontainer : e)
            .filter((e) => e.switch === true)
          }
          shops={shops}
          orders={orders
            .filter((e) => user ? e.user === user : e)
            // .filter((e) => areacontainer ? e.container === areacontainer : e)
          }
          endDate={endValue} startDate={startValue} report={
            reports
              .filter((e) => user ? e.user === user : e)
              .filter((e) => searchName ? e.title.toLowerCase().includes(
                searchName.toLowerCase()) : e)
          } user={user} users={users}
        />}
      </div>
    </>
  )
}

export default ContainerReport
