import axios from 'axios';
import React, { useState } from 'react'

const MessageAddModal = ({ closeModal }) => {
    const [content, setContent] = useState('');


    const addMessage = () => {
        axios.post('https://dev.turbodelivery.mn/msg', {
            content: content,
            // user: user._id
        })
            .then(() => window.location.reload(false))
            .catch((e) => e)
    }
    return (
        <div className='blur_container'>
            <div className='modal'>
                <div className='two-line'>
                    <h2>Мессэж нэмэх</h2>
                    <h2 style={{ cursor: 'pointer' }} onClick={closeModal}>X</h2>
                </div>
                <label>
                    Мессэж:
                    <input type="text" onChange={(e) => setContent(e.target.value)} value={content} />
                </label>
                <div className='button_container'>
                    <button onClick={addMessage} className='button-white' style={{ color: '#2c9961' }}>Хадгалах</button>
                    <button onClick={closeModal} className='button-white' style={{ color: 'red' }}>Хаах</button>
                </div>
            </div>
        </div>
    )
}

export default MessageAddModal
