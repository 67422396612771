import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination'; // Энэ мөрөнд TablePagination компонентийг нэмнэ
import { CiEdit, CiTrash } from "react-icons/ci";
import CategoryEditModal from '../Modal/EditModal/CategoryEditModal';
import dayjs from 'dayjs';
import axios from 'axios';
import MessageEditModal from '../Modal/EditModal/MessageEditModal';

export default function MessageTable({ messages }) {
    const [rowsPerPage, setRowsPerPage] = React.useState(25);
    const [page, setPage] = React.useState(0); // Энэ мөрөнд page-г нэмнэ
    const [id, setId] = React.useState('');
    const [data, setData] = React.useState({});
    const [isModal, setModal] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0); // Энэ мөрөнд setPage функцыг дуудаж 0-р тогтоож өгнө
    };

    const closeModal = () => {
        setModal(false);
    }

    const deleteMsg = () => {
        axios.delete('https://dev.turbodelivery.mn/msg/id/' + id)
            .then(() => window.location.reload(false))
            .catch((e) => console.log(e));

    }
    return (
        <>
            {isLoading ? <div className='loader-container'><div className='loader'></div></div> : null}
            {isModal && id && data ? <MessageEditModal id={id} msg={data} closeModal={closeModal} /> : null}
            <TableContainer style={{ maxHeight: '75vh', overflowY: 'auto' }} component={Paper}>
                <Table sx={{ minWidth: 650, maxWidth: '88vw' }} aria-label="simple table">
                    <TableHead>
                        <TableRow style={{ background: '#2c9961', color: '#fff' }}>
                            <TableCell style={{ color: '#fff', fontSize: 16 }}>Id</TableCell>
                            <TableCell style={{ color: '#fff', fontSize: 16 }}>Мессэж</TableCell>
                            <TableCell style={{ color: '#fff', fontSize: 16 }}>Үүсгэсэн огноо</TableCell>
                            <TableCell style={{ color: '#fff', fontSize: 16 }} align="center">Үйлдэл</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {messages
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((row, index) => (
                                <TableRow className='hover'
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left">{index + 1}</TableCell>
                                    <TableCell component="th" scope="row">
                                        {row.content}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        {dayjs(row.createdAt).format('YYYY-MM-DD')}
                                    </TableCell>
                                    <TableCell align="center">
                                        <CiEdit
                                            onClick={() => {
                                                setModal(true);
                                                setId(row._id);
                                                setData(row);
                                            }}
                                            size={30} style={{ cursor: 'pointer' }} color='blue'
                                        />
                                        <CiTrash
                                            onClick={() => {
                                                setId(row._id);
                                                deleteMsg();
                                            }}
                                            size={30} style={{ cursor: 'pointer' }} color='red' />
                                    </TableCell>

                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    count={messages.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
        </>
    );
}