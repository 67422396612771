import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CiEdit } from "react-icons/ci";
import dayjs from 'dayjs';
import { LiaCommentsSolid } from "react-icons/lia";
import CommentModal from '../Modal/Uttils/CommentModal';
import { MdEditNote } from "react-icons/md";
import Tooltip from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import DriverOrderStatusChangeModal from '../Modal/EditModal/DriverOrderStatusChangeModal';

export default function DriverHistoryTable({ orders, drivers, edit, user, shops }) {
    const [comment, setComment] = React.useState('');
    const [commentModal, setCommentModal] = React.useState(false);
    const [isModal, setIsModal] = React.useState(false);
    const [id, setId] = React.useState('');
    const [status, setStatus] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [address, setAddress] = React.useState('');

    const closeModal = () => {
        setCommentModal(false);
        setIsModal(false);
    }

    return (
        <>
            {isModal && id !== '' ? <DriverOrderStatusChangeModal orderStatus={status} phone={phone}
                address={address} id={id} closeModal={closeModal} /> : <></>}
            {commentModal ? <CommentModal closeModal={closeModal} text={comment} /> : <></>}
            <TableContainer style={{ maxHeight: '75vh', overflowY: 'auto' }} component={Paper}>
                <Table sx={{ minWidth: 650, maxWidth: '88vw' }} aria-label="simple table">
                    <TableHead>
                        <TableRow style={{ background: '#2c9961', color: '#fff' }}>
                            <TableCell className='table-title' style={{ color: '#fff', width: '20px' }}>Id</TableCell>
                            <TableCell className='table-title' style={{ color: '#fff', width: '170px' }}>Утас / Хаяг</TableCell>
                            <TableCell className='table-title' style={{ color: '#fff', width: '100px' }}>Харилцагч</TableCell>
                            <TableCell className='table-title' style={{ color: '#fff', width: '170px' }} align="center">Төлөв</TableCell>
                            <TableCell className='table-title' style={{ color: '#fff', width: '170px' }} align="center">Нийт дүн</TableCell>
                            <TableCell className='table-title' style={{ color: '#fff', width: '170px' }} align="center">Үүсэгэсэн огноо</TableCell>
                            <TableCell className='table-title' style={{ color: '#fff', width: '170px' }} align="center">Үйлдэл дарсан огноо</TableCell>
                            <TableCell className='table-title' style={{ color: '#fff', width: '250px' }} align="center">Жолооч</TableCell>
                            <TableCell className='table-title' style={{ color: '#fff', width: '170px' }} align="center">Үйлдэл</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {orders
                            .map((row, index) => (
                                <TableRow className='hover'
                                    key={index}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                // style={{position: 'absolute', display: 'flex', alignItems: 'center', gap: '10px', justifyContent: 'center'}} 
                                >
                                    <TableCell className='table-item' align="left">{index + 1}</TableCell>
                                    <TableCell
                                        className={
                                            row.status === 'Хойшлуулсан' || row.status === 'Маргааш авах' ? 'isFuture table-item' : 'table-item'
                                        }
                                        component="th" scope="row">
                                        {row.phone} <br /> {row.address}
                                    </TableCell>
                                    <TableCell className='table-item' align="center">
                                        {row.shop}
                                    </TableCell>
                                    <TableCell className='table-item' align="center">
                                        <div className='line-start'>
                                            {row.comment ? <LiaCommentsSolid
                                                onClick={() => {
                                                    setCommentModal(true);
                                                    setComment(row.comment);
                                                }}
                                                size={20} color='red' cursor={'pointer'} /> : <></>}
                                            <p className={row.status === 'Хүлээгдэж байна' ? 'green-border' :
                                                row.status === 'Жолооч авсан' ? 'blue-border' :
                                                    row.status === 'Хүргэсэн' ? 'full-green' :
                                                        row.status === 'Хаягаар очсон' ? 'full-red' :
                                                            row.status === 'Маргааш авах' ? 'full-blue' :
                                                                row.status === 'Хойшлуулсан' ? 'full-yellow'
                                                                    : 'red-border'}>
                                                {row.status}
                                            </p>
                                        </div>
                                    </TableCell>
                                    <TableCell className='table-item' align="center">{new Intl.NumberFormat('en-Us').format(row.price)}</TableCell>
                                    <TableCell className='table-item' style={{ width: '100px' }} align="center">{dayjs(row.createdAt).format('YYYY-MM-DD')}</TableCell>
                                    <TableCell className='table-item' style={{ width: '100px' }} align="center">{dayjs(row.orderDate).subtract(8, 'hour').format('YYYY-MM-DD')}</TableCell>
                                    <TableCell className='table-item' align="center" >
                                        {
                                            drivers.filter((e) => e._id === row.driver).map((e) => (
                                                <p>{e.name}</p>
                                            ))
                                        }
                                    </TableCell>
                                    <TableCell className='table-item' align="center">
                                        <div className="line" style={{ gap: 0 }}>
                                            {row.status !== 'Жолооч авсан' ? null :
                                                <Tooltip title="Filter list">
                                                    <IconButton>
                                                        <CiEdit
                                                            onClick={() => edit({ id: row._id })}
                                                            size={30} style={{ paddingRight: '0px', cursor: 'pointer' }} color='blue'
                                                        />
                                                    </IconButton>
                                                </Tooltip>
                                            }
                                            {/* <Tooltip title='Төлөв өөрчлөх'>
                                            <MdEditNote size={30} />
                                        </Tooltip> */}
                                            {/* {isModal && id !== '' ? <DriverOrderDetialModal closeModal={closeModal} id={id} /> : <></>} */}

                                            <Tooltip title="Filter list">
                                                <IconButton>
                                                    <MdEditNote
                                                        onClick={() => {
                                                            setId(row._id);
                                                            setIsModal(true);
                                                            setAddress(row.address);
                                                            setPhone(row.phone);
                                                            setStatus(status);
                                                        }}
                                                        size={30} color='green' />
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}