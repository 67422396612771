import React, { useEffect, useState } from 'react'
import styles from './Dashboard.module.css'
import Navbar from '../../Components/Navbar/Navbar'
import axios from 'axios';
import dayjs from 'dayjs';
import UserAddModal from '../../Utills/Modal/UserAddModal';
import UsersTable from '../../Utills/Table/UserTable';
import { Link } from 'react-router-dom';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

const Dashboard = ({ ordersCatch }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [isModal, setIsModal] = useState(false);
  const [orders, setOrders] = useState(ordersCatch);
  const [users, setUsers] = useState([]);
  const [shops, setShops] = useState([]);
  const [role, setRole] = useState('');
  const [user, setUser] = useState('');
  const [search, setSeach] = useState('');
  const [isEndCalendar, setIsEndCalendar] = useState(false);
  const [endValue, setEndValue] = useState(dayjs());

  useEffect(() => {
    if (isLoading) {
      window.localStorage.setItem('side-id', 0);
      axios.get('https://dev.turbodelivery.mn/user')
        .then((e) => {
          setUsers(e.data.data);
          setIsLoading(false);
        })
        .catch((e) => console.log(e));

      // axios.get('https://dev.turbodelivery.mn/shop')
      //   .then((e) => setShops(e.data.data.filter((e) => e.visible !== 'true')))
      //   .catch((e) => console.log(e));

      // axios.get('https://dev.turbodelivery.mn/order?sort=-createdAt')
      //   .then((e) => { setOrders(e.data.data); setIsLoading(false) })
      //   .catch((e) => console.log(e));
    }
  }, [isLoading])

  const closeModal = () => {
    setIsModal(false);
  }

  return (
    <div>
      {isLoading ? <div className="loader-container"><div className='loader'></div></div> : <></>}
      {isModal ? <UserAddModal closeModal={closeModal} /> : <></>}
      <Navbar title={'Хянах самбар'} />
      <div className={styles.container}>
        <label>
          Дэлгүүрээр шүүх:
          <select onChange={(e) => setUser(e.target.value)} name="" id="">
            <option value="">Бүгд</option>
            {users.filter((e) => e.shop).map((e) => (
              <option value={e._id}>{e.shop}</option>
            ))}
          </select>
        </label>

        <div style={{ width: window.screen.width < 769 ? '100vw' : '17vw', zIndex: 1 }}>
          {isEndCalendar ? (
            <div className="calendar-div">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                  <DemoItem>
                    <p onClick={() => setIsEndCalendar(false)}>Hide</p>
                    <DateCalendar value={endValue}
                      onChange={(newValue) => { setEndValue(newValue); setIsEndCalendar(false) }} />
                  </DemoItem>
                </DemoContainer>
              </LocalizationProvider>
            </div>
          ) : (
            <label className='calendar-label' onClick={() => setIsEndCalendar(true)}>
              {/* End date: */}
              Эхлэх хугацаа:
              <p className='calendar'>{endValue.format('YYYY-MM-DD')}</p>
            </label>
          )}
        </div>
      </div>
      <div className={styles.container}>
        <Link to={'/orderdetial/Хүлээгдэж байна/' + endValue.format('YYYY-MM-DD')} className='dashboard-card'>
          <p>Шинэ захиалга</p>
          <div className='divider-dashboard'></div>
          <h3>
            {orders.filter((e) => user ? e.user === user : e).filter((e) => e.status.includes('Хүлээгдэж байна')).length}
          </h3>
        </Link>
        <Link to={'/orderdetial/Жолооч авсан/' + endValue.format('YYYY-MM-DD')} className='dashboard-card'>
          <p>Жолооч хүлээн авсан</p>
          <div className='divider-dashboard'></div>
          <h3>
            {orders.filter((e) => user ? e.user === user : e).filter((e) => e.status.includes('Жолооч авсан')
              && dayjs(e.orderDate).subtract(8, 'hour').format('YYYY-MM-DD') === endValue.format('YYYY-MM-DD')).length}
          </h3>
        </Link>
        <Link to={'/orderdetial/Буцаагдсан/' + endValue.format('YYYY-MM-DD')} className='dashboard-card'>
          <p>Авахаа больсон</p>
          <div className='divider-dashboard'></div>
          <h3>
            {orders.filter((e) => user ? e.user === user : e).filter((e) => e.status.includes('Буцаагдсан') &&
              dayjs(e.orderDate).subtract(8, 'hour').format('YYYY-MM-DD') === endValue.format('YYYY-MM-DD')).length}
          </h3>
        </Link>
        <Link to={'/orderdetial/Хүргэсэн/' + endValue.format('YYYY-MM-DD')} className='dashboard-card'>
          <p>Хүргэсэн</p>
          <div className='divider-dashboard'></div>
          <h3>
            {orders.filter((e) => user ? e.user === user : e).filter((e) => (e.status.includes('Хүргэсэн')
              || e.status.includes('Хэсэгчлэн хүргэсэн')) &&
              dayjs(e.orderDate).subtract(8, 'hour').format('YYYY-MM-DD') === endValue.format('YYYY-MM-DD')).length}
          </h3>
        </Link>
        <Link to={'/orderdetial/Хойшлуулсан/' + endValue.format('YYYY-MM-DD')} className='dashboard-card'>
          <p>Хойшилсон</p>
          <div className='divider-dashboard'></div>
          <h3>
            {orders.filter((e) => user ? e.user === user : e).filter((e) => (e.status.includes('Хойшлуулсан') || e.status.includes('Маргааш авах'))
              && dayjs(e.orderDate).subtract(8, 'hour').format('YYYY-MM-DD') === endValue.format('YYYY-MM-DD')).length}
          </h3>
        </Link>
        <Link to={'/orderdetial/Хаягаар очсон/' + endValue.format('YYYY-MM-DD')} className='dashboard-card'>
          <p>Хаягаар очсон</p>
          <div className='divider-dashboard'></div>
          <h3>
            {orders.filter((e) => user ? e.user === user : e).filter((e) => e.status.includes('Хаягаар очсон')
              && dayjs(e.orderDate).subtract(8, 'hour').format('YYYY-MM-DD') === endValue.format('YYYY-MM-DD')).length}
          </h3>
        </Link>
      </div>
      <div className={styles.user_container}>
        <div className="line-three">
          <button onClick={(e) => setIsModal(true)} className='button'>Хэрэглэгч нэмэх</button>
          <label>
            Нэрээр хайх:
            <input className='search' value={search} onChange={(e) => setSeach(e.target.value)} type="text" name="" id="" />
          </label>
          <label>
            Эрхээр шүүх:
            <select onChange={(e) => setRole(e.target.value)} name="" id="">
              <option value=""></option>
              <option value="driver">Жолооч</option>
              <option value="admin">Админ</option>
              <option value="operator">Нярав</option>
            </select>
          </label>
        </div>
        <UsersTable users={users.filter((e) => e.role !== 'user')} roleFilter={role} search={search} isDriver={true} />
      </div>
    </div>
  )
}

export default Dashboard
