import axios from 'axios';
import React, { useState } from 'react';
import UbData from '../../../Data/State';

const EditStateModal = ({ closeModal, data }) => {
    const [name, setName] = useState(data.name);
    const [areas, setAreas] = useState(data.areas);

    const ubData = UbData();

    const handleAreaChange = (index, value) => {
        const newAreas = [...areas];
        newAreas[index].state = value;
        // Reset the districts when a new state is selected
        newAreas[index].districts = [{ district: '' }];
        setAreas(newAreas);
    };

    const handleDistrictChange = (areaIndex, districtIndex, value) => {
        const newAreas = [...areas];
        newAreas[areaIndex].districts[districtIndex].district = value;
        setAreas(newAreas);
    };

    const addDistrict = (areaIndex) => {
        const newAreas = [...areas];
        newAreas[areaIndex].districts.push({ district: '' });
        setAreas(newAreas);
    };

    const removeDistrict = (areaIndex, districtIndex) => {
        const newAreas = [...areas];
        newAreas[areaIndex].districts.splice(districtIndex, 1);
        setAreas(newAreas);
    };

    const addArea = () => {
        setAreas([...areas, { state: '', districts: [{ district: '' }] }]);
    };

    const removeArea = (areaIndex) => {
        const newAreas = [...areas];
        newAreas.splice(areaIndex, 1);
        setAreas(newAreas);
    };

    const addCategory = () => {
        axios.put('https://dev.turbodelivery.mn/state/id/' + data._id, {
            name: name,
            areas: areas,
        })
            .then(() => window.location.reload(false))
            .catch((e) => console.error(e));
    };

    return (
        <div className='blur_container'>
            <div className='modal'>
                <div className='two-line'>
                    <h2>Бүс нэмэх</h2>
                    <h2 style={{ cursor: 'pointer' }} onClick={closeModal}>X</h2>
                </div>
                <label>
                    Бүсийн нэр:
                    <input type="text" onChange={(e) => setName(e.target.value)} value={name} />
                </label>

                {areas.map((area, areaIndex) => (
                    <div key={areaIndex}>
                        <label>
                            Дүүргийн нэр:
                            <select onChange={(e) => handleAreaChange(areaIndex, e.target.value)} value={area.state}>
                                <option value="">Сонгох</option>
                                {Object.keys(ubData).map((districtName) => (
                                    <option key={districtName} value={districtName}>
                                        {districtName}
                                    </option>
                                ))}
                            </select>
                        </label>
                        {area.districts.map((district, districtIndex) => (
                            <div style={{ padding: '10px', display: 'flex', gap: '10px' }} key={districtIndex}>
                                <label>
                                    Хороо:
                                    <select onChange={(e) => handleDistrictChange(areaIndex, districtIndex, e.target.value)} value={district.district}>
                                        <option value="">Сонгох</option>
                                        {area.state && ubData[area.state].map((subDistrict) => (
                                            <option key={subDistrict} value={subDistrict}>
                                                {subDistrict}
                                            </option>
                                        ))}
                                    </select>
                                </label>
                                <button className='button-white' style={{ fontSize: '14px', color: 'red', padding: '1px 5px' }} onClick={() => removeDistrict(areaIndex, districtIndex)}>Хороо хасах</button>
                            </div>
                        ))}
                        <button className='button' onClick={() => addDistrict(areaIndex)}>Хороо нэмэх</button>
                    </div>
                ))}
                <div className='button_container'>
                    <button className='button-white' onClick={addArea}>Дүүрэг нэмэх</button>
                    <button className='button-white' style={{ color: 'red' }} onClick={() => removeArea(areas.length - 1)}>Дүүрэг Хасах</button>
                </div>

                <div className='button_container'>
                    <button onClick={addCategory} className='button-white' style={{ color: '#2c9961' }}>Хадгалах</button>
                    <button onClick={closeModal} className='button-white' style={{ color: 'red' }}>Хаах</button>
                </div>
            </div>
        </div>
    );
};

export default EditStateModal;
